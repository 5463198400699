import adminLayout from '@/layouts/Admin'
import currentRouteMixin from '@/mixins/current-route.js'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'MemoryCardsImagesView',
    data() {
        return {
            memoryCardImage: {},
            currentMemoryCardsImageId: null,
            fileImage: null
        }
    },
    mixins: [currentRouteMixin],
    components: {
        adminLayout
    },
    mounted() {
        this.currentMemoryCardsImageId = this.currentRoute().params.id

        this.getMemoryCardImage()
    },
    methods: {
        getMemoryCardImage() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/memory-cards-images/' + self.currentMemoryCardsImageId,
                method: 'GET'
            }).then(function(response) {
                self.memoryCardImage = response.data.memory_cards_images
            })
        },
        deleteMemoryCardImage() {
            const self = this

            httpAxios({
                url: self.$backendUrl + '/api/v1/memory-cards-images/' + self.currentMemoryCardsImageId,
                method: 'DELETE'
            }).then(function() {
                self.$router.push({ name: 'admin.memorycards.images' })
            })
        },
        updateMemoryCardImag() {
            const self = this
            let formData = new FormData()

            formData.append('_method', 'PUT')
            formData.append('name', self.memoryCardImage.name)
            formData.append('level', self.memoryCardImage.level)
            if(self.fileImage) formData.append('image', self.fileImage)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/memory-cards-images/' + self.currentMemoryCardsImageId,
                method: 'POST',
                data: formData
            }).then(function(response) {
                self.memoryCardImage = response.data.memory_card_image

                self.notifySuccess()
            })
        },
        handleFileImage(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileImage = null
            }

            this.fileImage = files[0]
        },
    }
}